import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Col,
  Row,
  Spin,
} from 'antd';
import {
  circleOptions,
  GOOGLE_MAPS_KEY,
  googleMapOptions,
} from 'Api/MapConfig';
import { ReactComponent as LocationIcon } from 'Assets/Icons/locationIcon.svg';
import SubLabelWrapper from 'Components/SubLabelWrapper';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'Redux/RootReducer';

import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

const libraries: any = ["places"];

interface LocationPickerProps {
    setShowMapPage: Dispatch<SetStateAction<boolean>>
    setAutoCompleteValue: Dispatch<SetStateAction<any>>
    autoCompleteValue: any
    initKycFormValues: () => void

}


export const LocationPicker = ({ setShowMapPage, autoCompleteValue, setAutoCompleteValue, initKycFormValues }: LocationPickerProps) => {

    //var
    const { kycFormValues } =
        useSelector((state: RootState) => state?.protectedReducer)

    //hooks
    const location = useLocation<any>()

    //ref
    const locationRef = useRef<HTMLDivElement>(null)
    const mapRef = useRef<any>();


    //state
    const [centerVal, setCenterVal] = useState({
        lat: 27.6663423,
        lng: 85.3330053
    })

    const [longitude, setLongitude] = useState<number>(27.68433498045849,)
    const [latitude, setLatitude] = useState<number>(85.31859999833024)

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_KEY,
        libraries,
    });

    const pantTo = useCallback(({ lat, lng, zoomLevel = 15 }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(zoomLevel);
    }, []);

    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            mapRef.current = map;
            pantTo({
                lat: latitude,
                lng: longitude,
            });
        },
        [latitude, longitude, pantTo]
    );



    const showMapHandler = () => setShowMapPage(true);

    const autoCompleteHandler = (val: any) => {
        initKycFormValues()
        setAutoCompleteValue(val);
        setShowMapPage(true);
    };

    useEffect(() => {
        if (kycFormValues?.location_map) {
            setCenterVal({
                lat: Number(kycFormValues.location_map.latitude),
                lng: Number(kycFormValues.location_map.longitude)
            })
        }
    }, [kycFormValues?.location_map])

    useEffect(() => {
        if (location.state?.scrollPosition === "location") {

            locationRef.current?.scrollIntoView({ behavior: 'smooth', })
        }
    }, [location.state?.scrollPosition,])

    if (!isLoaded)
        return (
            <div className="d-flex align-items-center flex-center h-100vh">
                <Spin></Spin>
            </div>
        );
    if (loadError) {
        return <div>Cannot Get Google maps at this time</div>;
    }

    return (

        <>
            <Row gutter={[16, 16]} className='mt-2 groupWrapper' ref={locationRef}>
                <Col xs={24} lg={12}  >
                    <SubLabelWrapper
                        label="Add Location Map"
                        subLabel="Select your location"
                        render={
                            <>
                                <GooglePlacesAutocomplete
                                    debounce={800}
                                    onLoadFailed={(error) =>
                                        console.error("Could not inject Google script", error)
                                    }
                                    minLengthAutocomplete={2}
                                    selectProps={{
                                        value: autoCompleteValue,
                                        onChange: autoCompleteHandler,
                                        placeholder: "Enter a place",
                                    }}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ["np"],
                                        },
                                    }}
                                />
                                <div
                                    className="d-flex align-items-center cursor-hover mt-1"
                                    onClick={() => {

                                        // dispatchKycFormValues()
                                        showMapHandler()
                                    }}
                                >
                                    <LocationIcon style={{ marginRight: 10 }} />
                                    Set on Map
                                </div>
                            </>
                        }
                    />
                </Col>
                <Col xs={24} lg={12} >
                    <div style={{ height: "100%", width: "100%" }}>
                        <GoogleMap
                            mapContainerStyle={{
                                height: "400px",
                                borderRadius: 16,
                                marginLeft: 30
                            }}
                            zoom={15}
                            options={googleMapOptions}
                            center={{
                                ...centerVal
                            }}
                            ref={mapRef}
                            onLoad={onMapLoad}
                        >

                            <>
                                <Marker
                                    position={centerVal}
                                />
                                <Circle center={
                                    centerVal
                                } radius={150} options={circleOptions} />
                            </>
                        </GoogleMap>
                    </div>
                </Col>
            </Row>
        </>
    )
}
