// import ProtectedLayout from "Layouts/ProtectedLayout";
import { useEffect } from 'react';

import {
  Col,
  Row,
  Spin,
} from 'antd';
import ScrollToTop from 'Components/ScrollToTop';
import { AnimatePresence } from 'framer-motion';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Redirect,
  Route,
  RouteChildrenProps,
  RouteComponentProps,
  Switch,
  useLocation,
  useParams,
} from 'react-router';
import {
  getKycDetailRequest,
  resetGlobalTimer,
  setGlobalTimer,
} from 'Redux/Protected/Action';
import { RootState } from 'Redux/RootReducer';
import {
  getAddressRequest,
  getBankRequest,
  getBrokerRequest,
  getOrganizationRequest,
  getPaymentRateRequest,
} from 'Redux/Static/Action';

import DocsRequired from '../Views/Static/docsRequired';
import { routeConfig } from './routeConfig';

export const ProtectedRoute = (props: RouteChildrenProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {oid} = useParams<any>()
  const { pathname } = location;
  const { kycDetails, loading, currentUserId, forceReloadCount } = useSelector(
    (state: RootState) => state.protectedReducer
  );

  useEffect(() => {
    if (!(localStorage.getItem("user-logged-in") === "true")) {
      window.location.replace("/auth/general-details");
    } else {
      const userId = localStorage.getItem("userId");
      dispatch(getAddressRequest());
      dispatch(getBankRequest());
      dispatch(getOrganizationRequest());
      dispatch(getKycDetailRequest(userId));
      dispatch(getPaymentRateRequest());
      dispatch(getBrokerRequest());
    }
  }, []);

  // useEffect(() => {
  //   if(window.location.pathname === "/app/profile" && localStorage.getItem("user-logged-in") === "true" ) {
  //     const userId = localStorage.getItem("userId");
  //     dispatch(getKycDetailRequest(userId));

  //   }
  // }, [window.location.pathname, dispatch])



  

  // useEffect(() => {
  //   //auto redirection to last saved pages is handled from here
  //   //first if case is for esewa cases
  //   if (
  //     window.location.pathname === "/app/meroshare/payment" ||
  //     window.location.pathname === "/app/dematt/payment"
  //   ) {
  //     //esewa and khalti cases handled here
  //     const parsed: any = queryString.parse(window.location.search);

  //     if (parsed["?esewasuccess"] || parsed["?khaltisuccess"]) {
  //       if (Object.keys(kycDetails).length && kycDetails?.others?.length) {
  //         let dispatchIdx: any = 0;
  //         //find the currentuser index from kycDetails.others (explained below)
  //         for (let i = 0; i < kycDetails.others.length; i++) {
  //           if (
  //             kycDetails.others[i].id === parsed["?esewasuccess"] ||
  //             kycDetails.others[i].id === parsed["?khaltisuccess"]
  //           ) {
  //             dispatchIdx = i;
  //             break;
  //           }
  //         }
  //         //handling timer to continue before we were redirected to esewa website for payments
  //         const timeCount = Number(parsed?.timecount) ?? 0;
  //         let initTimer;
  //         if (Number.isNaN(timeCount)) {
  //           //nan case
  //           initTimer = 0;
  //         } else {
  //           initTimer = timeCount;
  //         }
  //         dispatch(setInitialGlobalTImer(initTimer));

  //         //We lose our redux state when we redirected to esewa website for payment
  //         //So we again set currentUser and setting our kycFormValues
  //         dispatch(setNewKycFormValues(kycDetails?.others[dispatchIdx]));
  //         dispatch(
  //           setCurrentUser(parsed["?esewasuccess"] ?? parsed["?khaltisuccess"])
  //         );
  //       }
  //     } else {
  //       //   //just a preventive error handling
  //       props.history.replace("/app/profile");
  //     }
  //   } else {
  //     // Auto Redirection to last saved page is handled here
  //     if (currentUserId !== "") {
  //       if (Object.keys(kycDetails).length && kycDetails?.others?.length) {
  //         let dispatchIdx: any = 0;
  //         //finding index of the selected user
  //         for (let i = 0; i < kycDetails.others.length; i++) {
  //           if (kycDetails.others[i].id === currentUserId) {
  //             dispatchIdx = i;
  //             break;
  //           }
  //         }
  //         //The selected user's data is dispatched in to our redux store in kycFormValues
  //         dispatch(setNewKycFormValues(kycDetails?.others[dispatchIdx]));

  //         //find pageNumber of the page to be redirected to
  //         const findPageNumber = findActivePage(
  //           kycDetails?.others[dispatchIdx]
  //         );
  //         //get the object corresponding to the pageNumber
  //         const findPageObject = routeConfig.filter(
  //           (each: any) => each.pageNumber === findPageNumber
  //         );
  //         //navigate to right screen from here
  //         const pageRoute = findPageObject[0].path ?? "/form-selection";
  //         props.history.push(`/app${pageRoute}`);
  //       }
  //     } else {
  //       //if no user reset the entire kycFormValues to empty object and push to profile page
  //       dispatch(setNewKycFormValues({}));
  //       props.history.replace("/app/profile");
  //     }
  //   }
  // }, [kycDetails, currentUserId, forceReloadCount]);

  // useEffect(() => {
  //   //manually back from browser garda ma if there is no "currentUserId" in redux store redirect to profile
  //   if (pathname !== "/app/profile") {
  //     if (pathname === "/app/meroshare/payment") return;
  //     if (pathname === "/app/dematt/payment") return;
  //     if (currentUserId === "") {
  //       window.location.replace("/app/profile");
  //     }
  //   }
  // }, [pathname]);

  useEffect(() => {
    let timer: any = null;
    if (currentUserId !== "") {
      timer = setInterval(() => {
        dispatch(setGlobalTimer());
      }, 1000);
    } else {
      clearInterval(timer);
      dispatch(resetGlobalTimer());
    }
    return () => {
      clearInterval(timer);
    };
  }, [currentUserId, forceReloadCount]);

  return (
    <>
      <ScrollToTop />

      {!(localStorage.getItem("user-logged-in") === "true") ? (
        <Row
          justify="center"
          align="middle"
          style={{ textAlign: "center", height: "100vh" }}
        >
          <Col span={24}>
            {" "}
            <Spin size="large" />
          </Col>
        </Row>
      ) : loading ? (
        <div className="d-flex align-items-center flex-center h-100vh">
          <Spin></Spin>
        </div>
      ) : (
      
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
              <Redirect
                exact
                from={`${props.match?.path}/`}
                to={`${props.match?.path}/profile`}
              />
              {routeConfig.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={`${props.match?.path}${route.path}`}
                    exact={route.exact}
                    render={(props2: RouteComponentProps<any>) => (
                      <route.commponent
                        {...props2}
                        // {...route.props}
                        pageNumber={route.pageNumber}
                      />
                    )}
                  />
                );
              })}
              <Route
                path={`${props.match?.path}/dematt/required-documents`}
                exact
                component={DocsRequired}
              />
              <Route
                path={`${props.match?.path}/trading/required-documents`}
                exact
                component={DocsRequired}
              />

              <Redirect to="/error" />
            </Switch>
          </AnimatePresence>
      )}
    </>
  );
};

export default ProtectedRoute;
